window.onerror = (message, url, line, column, error) => {
  const data = {
    'column': column,
    'error': error,
    'line': line,
    'message': message,
    'page': url,
    'referer': document.referrer,
    'user-agent': navigator.userAgent
  };

  fetch(window._config.apiUrl + '/errors/', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  return false;
};
